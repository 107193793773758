import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { IUser } from "../models/user";

const GuestGuard = ({ children }: any) => {
  const user = useSelector(({ UserSlice }: any) => UserSlice.user as IUser);

  if (user?.email) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <div>guest guard</div>
      {children}
    </>
  );
};

export default GuestGuard;
