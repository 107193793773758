import { api } from "../../services/helpers";
import { HttpService } from "../http/HttpService";

interface IAjaxAuthProps {
  login: string;
  password: string;
  userRole: string;
}

interface IAjaxAuthResponse {
  sessionToken: string;
  userId: string;
  refreshToken: string;
  messageId?: string;
  message?: string;
  errors?: any[];
}

const httpService = new HttpService(`${api}/ajax/`);

export class AjaxService {
  async authorize(auth: IAjaxAuthProps): Promise<IAjaxAuthResponse> {
    return await httpService.post<IAjaxAuthResponse>("auth", auth);
  }

  async bindedHubs(auth: any): Promise<any> {
    if (!auth.login) return;

    return await httpService.post<any>("hubs", auth);
  }

  async bindedHubDetail(auth: any, bindedHubs: any[]): Promise<any> {
    return await httpService.post<any>("hub-detail", {
      auth,
      bindedHubs,
    });
  }

  async bindedHubDetailActual(bindedHubs: any[]): Promise<any> {
    return await httpService.post<any>("hub-detail-actual", bindedHubs);
  }
}
