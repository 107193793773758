import { useState } from "react";
import { Button, Form, Input, Modal, Radio } from "antd";
import clientsService from "../../services/clients.service";
import { useSelector } from "react-redux";
interface Values {
  title: string;
  description: string;
  modifier: string;
}

interface CollectionCreateFormProps {
  open: boolean;
  onCreate: (values: Values) => void;
  onCancel: () => void;
}

export enum IClientType {
  Cloud = 0,
  Local = 1,
}

const CollectionCreateForm: React.FC<CollectionCreateFormProps> = ({
  open,
  onCreate,
  onCancel,
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();

  return (
    <Modal
      open={open}
      title="Создание нового клиента"
      okText="Создать"
      cancelText="Отменить"
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            setConfirmLoading(true);
            onCreate(values);

            setTimeout(() => {
              setConfirmLoading(false);
              form.resetFields();
            }, 1000);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ type: "cloud" }}
      >
        <Form.Item
          label="Имя Фамилия"
          style={{ marginBottom: 0 }}
          required={true}
        >
          <Form.Item
            name="firstName"
            rules={[
              {
                required: true,
                message: "Имя обязательно!",
              },
            ]}
            style={{ display: "inline-block", width: "calc(50% - 8px)" }}
          >
            <Input placeholder="Введите имя" />
          </Form.Item>
          <Form.Item
            name="lastName"
            rules={[
              {
                required: true,
                message: "Фамилия обязательна!",
              },
            ]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 8px",
            }}
          >
            <Input placeholder="Введите фамилию" />
          </Form.Item>
        </Form.Item>

        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              required: true,
              message: "E-mail обязателен!",
            },
          ]}
        >
          <Input type="email" placeholder="example@example.com" />
        </Form.Item>

        <Form.Item
          name="address"
          label="Адрес"
          rules={[
            {
              required: true,
              message: "Адрес обязателен!",
            },
          ]}
        >
          <Input type="text" placeholder="Chisinau, Moldova" />
        </Form.Item>

        <Form.Item
          name="phone"
          label="Телефон"
          rules={[
            {
              required: true,
              message: "Телефон обязателен!",
            },
          ]}
        >
          <Input type="tel" placeholder="079 715 776" />
        </Form.Item>

        {/*  <Form.Item
          name="type"
          className="collection-create-form_last-form-item"
        >
          <Radio.Group defaultValue={IClientType.Cloud}>
            <Radio value={IClientType.Cloud}>Облачно</Radio>
            <Radio value={IClientType.Local}>Локально</Radio>
          </Radio.Group>
        </Form.Item> */}
      </Form>
    </Modal>
  );
};

export const CreateClient = () => {
  const user = useSelector(({ UserSlice }: any) => UserSlice.user);
  const [open, setOpen] = useState(false);

  const onCreate = async (values: any) => {
    try {
      const client = { ...values, balance: 0, action_by: user.email };
      const response = await clientsService.createClient(client);
      // alert(response);
      window.location.reload();
      setOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <Button
        type="primary"
        onClick={() => setOpen(true)}
        style={{ marginBottom: 16 }}
      >
        Создать клиента
      </Button>
      <CollectionCreateForm
        open={open}
        onCreate={onCreate}
        onCancel={() => setOpen(false)}
      />
    </div>
  );
};
