import { IDeleteHubData, IGroupInfoProps } from "../../models/ajax";
import {
  api,
  headers,
  spAjaxUsersURL,
  spBindedGroupToCamURL,
} from "../../services/helpers";

import { ISpAjaxUser } from "../ajax/model";
import { HttpService } from "../http/HttpService";

const httpService = new HttpService(`${api}/`);

export class SpAjaxUsersService {
  async saveUser(userData: any): Promise<ISpAjaxUser> {
    return await httpService.post<ISpAjaxUser>("sp-ajax-users", userData);
  }

  async getSPAjaxUsers(): Promise<ISpAjaxUser[]> {
    return await httpService.get<ISpAjaxUser[]>("sp-ajax-users");
  }

  async deleteSpUsers(userId: string): Promise<any> {
    return await httpService.post<any>("sp-ajax-users", { userId });
  }

  async deleteSpBindedUserHub(deleteData: IDeleteHubData): Promise<any> {
    return await httpService.post<any>("sp-ajax-users", deleteData);
  }

  async getBindedCamGroups(hubDocId: string): Promise<IGroupInfoProps[]> {
    return await httpService.post<IGroupInfoProps[]>("sp-ajax-users", {
      hubDocId,
    });
  }
}
