import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";

import useAuthenthication from "../hooks/useAuthentication";

const AuthGuard = ({ children }: any) => {
  const token = useSelector((state: any) => state.UserSlice.accessToken);
  const { logoutCall } = useAuthenthication();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  useEffect(() => {
    const checkAuthentication = async () => {
      if (!token) {
        setIsAuthenticated(false);
        return;
      }

      try {
        // Декодируем токен и проверяем его истечение
        const decoded: any = jwtDecode(token);
        const isTokenExpired = Date.now() >= decoded.exp * 1000;

        if (isTokenExpired) {
          logoutCall();
        } else {
          setIsAuthenticated(true);
        }
      } catch (error) {
        console.error("Invalid token or decoding failed:", error);
        setIsAuthenticated(false);
      }
    };

    checkAuthentication();
  }, [token]);

  if (isAuthenticated === null) {
    return <div>Loading...</div>; // Пока проверяем токен
  }

  if (!isAuthenticated) {
    return <Navigate to="/sign-in" replace />; // Если не авторизован, перенаправляем на логин
  }

  return (
    <>
      {children} {/* Если авторизован, рендерим защищённые компоненты */}
    </>
  );
};

export default AuthGuard;
