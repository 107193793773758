import { HttpService } from "../ducks/http/HttpService";
import { api } from "./helpers";

const httpService = new HttpService(`${api}`);

class OperatorService {
  async getOperatorsList() {
    return await httpService.get<any>("/operator/list");
  }

  async createOperator(props: any) {
    return await httpService.post<any>("/operator/create", props);
  }

  async deleteOperator(props: any) {
    return await httpService.post<any>("/operator/delete", props);
  }
}

export default new OperatorService();
