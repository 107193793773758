import { useState } from "react";
import { Button, Form, Input, Modal, Radio } from "antd";
import clientsService from "../../services/clients.service";
import { useSelector } from "react-redux";
import { IUser } from "../../models/user";
import { IClientType } from "../clients/create.client";

interface Values {
  title: string;
  description: string;
  modifier: string;
}

interface ICreateSpaceProps {
  clientId: string | undefined;
}

interface CollectionCreateFormProps {
  open: boolean;
  onCreate: (values: Values) => void;
  onCancel: () => void;
}

const CollectionCreateForm: React.FC<CollectionCreateFormProps> = ({
  open,
  onCreate,
  onCancel,
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();

  return (
    <Modal
      open={open}
      title="Создание нового объекта"
      okText="Создать"
      cancelText="Отменить"
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            setConfirmLoading(true);
            onCreate(values);

            setTimeout(() => {
              setConfirmLoading(false);
              form.resetFields();
            }, 1000);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ type: IClientType.Cloud }}
      >
        <Form.Item
          name="spaceName"
          label="Имя объекта"
          rules={[
            {
              required: true,
              message: "Имя обязательно!",
            },
          ]}
        >
          <Input placeholder="Введите имя" />
        </Form.Item>

        <Form.Item
          name="address"
          label="Адрес"
          rules={[
            {
              required: true,
              message: "Адрес обязателен!",
            },
          ]}
        >
          <Input type="text" placeholder="Chisinau, Moldova" />
        </Form.Item>

        <Form.Item name="description" label="Описание">
          <Input type="text" placeholder="Заметка ..." />
        </Form.Item>

        <Form.Item
          name="type"
          className="collection-create-form_last-form-item"
        >
          <Radio.Group>
            <Radio value={IClientType.Cloud}>Облачно</Radio>
            <Radio value={IClientType.Local}>Локально</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export const CreateSpace = (props: ICreateSpaceProps) => {
  const user = useSelector(({ UserSlice }: any) => UserSlice.user as IUser);
  const [open, setOpen] = useState(false);

  const onCreate = async (values: any) => {
    const clientId = props.clientId;
    if (!values.description) values.description = "";
    try {
      const clientSpace = { ...values, clientId, action_by: user.email };
      const response = await clientsService.createSpace(clientSpace);

      alert(response._id);
      window.location.reload();

      setOpen(false);
    } catch (e) {
      alert(e);
      console.log(e);
    }
  };

  return (
    <div>
      <Button
        type="primary"
        onClick={() => setOpen(true)}
        style={{ marginBottom: 16 }}
      >
        Создать объект
      </Button>
      <CollectionCreateForm
        open={open}
        onCreate={onCreate}
        onCancel={() => setOpen(false)}
      />
    </div>
  );
};
