import { HttpService } from "../ducks/http/HttpService";
import { api } from "./helpers";

const httpService = new HttpService(`${api}`);

class ClientService {
  async fetchClients() {
    return await httpService.get<any>("/clients");
  }

  async createClient(client: any) {
    return await httpService.post<any>("/clients", client);
  }

  async deleteClient(clientId: string) {
    return await httpService.delete<any>(`/clients/${clientId}`);
  }

  async fetchClientSpaces(clientId: string) {
    return await httpService.get<any>(`/client-space/${clientId}`);
  }

  async createSpace(space: any) {
    return await httpService.post<any>("/client-space", space);
  }

  async deleteSpace(spaceId: string) {
    return await httpService.delete<any>(`/client-space/${spaceId}`);
  }
}

export default new ClientService();
