interface IRenderGroupNameProps {
  name: string;
}

const RenderGroupName = ({ name }: IRenderGroupNameProps) => {
  console.log("NAME:", name);

  return <div style={{}}>{name && name}</div>;
};

export default RenderGroupName;
