import { HttpService } from "../ducks/http/HttpService";
import { IChannel } from "../models/dahua";
import { api } from "./helpers";

interface IDahuaAuthProps {
  login: string;
  password: string;
  ip: string;
  port: string;
  action_by: string;
}

const httpService = new HttpService(`${api}`);

class DahuaService {
  async verifyAuthorize(props: IDahuaAuthProps) {
    return await httpService.post<any>("/dahua/auth/check", props);
  }

  async addCumToHub(props: any) {
    return await httpService.post<any>("/sp-ajax-cams/add-cam-to-hub", props);
  }

  async saveCamera(props: any) {
    return await httpService.post<any>("/dahua/save-camera", props);
  }

  async getCamInfo(props: any) {
    return await httpService.post<any>(
      "/sp-ajax-cams/get-camera-detail",
      props
    );
  }

  async getCameraChannels(camId: string) {
    return await httpService.get<any>(`/sp-ajax-cams/detail-channel/${camId}`);
  }

  async getControlPanelInfo(camId: string) {
    return await httpService.get<any>(`/sp-ajax-cams/detail/${camId}`);
  }

  async videoAnalyseRule(props: any) {
    return await httpService.post<any>("/dahua/video-analyse", props);
  }

  async updateCamera(camera: any) {
    return await httpService.post<any>("/sp-ajax-cams/update-camera", camera);
  }

  async updateCameraRules(payload: any) {
    return await httpService.post<any>(
      "/sp-ajax-cams/update-camera-rules",
      payload
    );
  }

  async updateHubExecutingRule(props: any) {
    return await httpService.post<any>("/dahua/update-executing-rule", props);
  }

  async updateCameraChannel(channel: IChannel, camId: string) {
    const payload = { ...channel, camId };
    return await httpService.post<any>(
      "/sp-ajax-cams/update-camera-channel",
      payload
    );
  }
}

export default new DahuaService();
