import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { HubLocationService } from "../../ducks/hub-location/hub.location";

import { ISpaceDetail } from "../../models/space";

interface ICreateUserProps {
  HubLocationService: HubLocationService;
}

const deps: ICreateUserProps = {
  HubLocationService: new HubLocationService(),
};

interface ILocationDetailProps {
  space: ISpaceDetail;
}

export const LocationDetails = (props: ILocationDetailProps) => {
  return (
    <div style={{ marginBottom: 16 }}>
      <div>Адрес клиента: {props.space?.address}</div>
      <div>Имя обьекта: {props.space?.spaceName}</div>
      <div>Адресс объекта: {props.space?.address}</div>
    </div>
  );
};
