import { useState } from "react";
import { useDispatch } from "react-redux";

import { clearUserData, setUser } from "../redux/UserSlice";
import axios from "axios";
import { api } from "../services/helpers";

interface IAuthProps {
  email: string;
  password: string;
  tokenFromFlashDrive: string;
}

const useAuthenthication = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const signInCall = async ({
    email,
    password,
    tokenFromFlashDrive,
  }: IAuthProps) => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${api}/authentication/login`, {
        username: email,
        password,
        tokenFromFlashDrive,
      });

      const { access_token } = response.data;

      console.log("access token", access_token);

      // Сохраняем пользователя и токен в Redux
      dispatch(setUser({ user: { email }, accessToken: access_token }));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const logoutCall = () => {
    dispatch(clearUserData());
  };

  return { isLoading, signInCall, logoutCall };
};

export default useAuthenthication;
