import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Table, { ColumnsType } from "antd/es/table";
import { Space } from "antd";
import { SpAjaxUsersService } from "../../ducks/ajax-users/sp-ajax.users.service";
import { AjaxService } from "../../ducks/ajax/ajax.service";
import { HubLocationService } from "../../ducks/hub-location/hub.location";

interface ICreateUserProps {
  HubLocationService: HubLocationService;
  spAjaxUsersService: SpAjaxUsersService;
  ajaxService: AjaxService;
}

const deps: ICreateUserProps = {
  HubLocationService: new HubLocationService(),
  spAjaxUsersService: new SpAjaxUsersService(),
  ajaxService: new AjaxService(),
};

const DahuaControllerSpace = () => {
  const { id } = useParams();
  const [accountId, setAcccountId] = useState();

  const columns: ColumnsType<any> = [
    {
      title: "Id",
      key: "hubId",
      dataIndex: "hubId",
    },
    {
      title: "Action",
      key: "action",
      render: (_, hub) => {
        return (
          <Space size="middle">
            <Link to={`/space/${id}/hub-cam/${hub.hubId}`}>Камеры</Link>
          </Space>
        );
      },
    },
  ];

  const [listLoading, setListLoading] = useState(false);
  const [controllers, setControllers] = useState();

  const genBindedList = async () => {
    setListLoading(true);

    const bindedList = await deps.HubLocationService.getBindedHubsInLocation(
      id as string
    );

    if (bindedList) {
      setControllers(bindedList);
    }

    setListLoading(false);
  };

  useEffect(() => {
    genBindedList();
  }, [id]);

  return (
    <>
      <Table
        size="small"
        columns={columns}
        dataSource={controllers}
        rowKey={(i) => i._id}
        loading={listLoading}
      />
    </>
  );
};

export default DahuaControllerSpace;
