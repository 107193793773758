import { createSlice } from "@reduxjs/toolkit";

export const UserSlice = createSlice({
  name: "UserSlice",
  initialState: {
    user: {},
    accessToken: null,
  },
  reducers: {
    setUser(state, action) {
      state.user = action.payload.user;
      state.accessToken = action.payload.accessToken;
    },
    clearUserData(state) {
      state.user = {};
      state.accessToken = null;
    },
  },
});

export const { setUser, clearUserData } = UserSlice.actions;
export default UserSlice.reducer;
