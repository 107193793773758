export const headers = {
  "Content-Type": "application/json",
};

export const api = "https://api-staging.securitypark.md";
// export const api = "http://10.123.100.50:3005";

const AJAX = "ajax";
const DAHUA = "dahua";
const OPERATOR = "operator";

const SP_AJAX_USERS = "sp-ajax-users";

export const clientUrl = `${api}/clients`;

export const spaceUrl = `${api}/client-space`;

export const allClientHubs = `${api}/client-hubs/get-by-owner`;
export const addHubToSpace = `${api}/client-hubs/add-hub`;

export const authAJAXUserURL = `${api}/${AJAX}/auth`;
export const ajaxUserInfoURL = `${api}/${AJAX}/user-info`;
export const ajaxUserHubsURL = `${api}/${AJAX}/user-hubs`;

export const deleteHubAndCameraURL = `${api}/${AJAX}/delete-hub`;

export const dahuaAuthCheck = `${api}/${DAHUA}/auth/check`;
export const addCamToHub = `${api}/sp-ajax-cams/add-cam-to-hub`;
export const fetchCamsInHub = `${api}/sp-ajax-cams/get-hub-cameras`;

export const saveCamera = `${api}/${DAHUA}/save-camera`;

export const deleteCameraFromHub = `${api}/sp-ajax-cams/delete-camera`;

export const getCamDetails = `${api}/sp-ajax-cams/get-camera-detail`;
export const getControlPanelURL = (id: string) =>
  `${api}/sp-ajax-cams/detail/${id}`;
export const getCameraChannelURL = (id: string) =>
  `${api}/sp-ajax-cams/detail-channel/${id}`;

export const videoAnalyseRuleURL = `${api}/${DAHUA}/video-analyse`;
export const updateCameraDetailURL = `${api}/sp-ajax-cams/update-camera`;
export const updateCameraChannelURL = `${api}/sp-ajax-cams/update-camera-channel`;
export const updateCameraRulesURL = `${api}/sp-ajax-cams/update-camera-rules`;

export const updateExecutingRule = `${api}/${DAHUA}/update-executing-rule`;

export const getOperatorList = `${api}/${OPERATOR}/list`;
export const createOperatorURL = `${api}/${OPERATOR}/create`;
export const deleteOperatorURL = `${api}/${OPERATOR}/delete`;

/*  */
// new AjaxService;
/* export const ajaxAutorizeURL = `${api}/${AJAX}/auth`;
export const ajaxBindedHubURL = `${api}/${AJAX}/hubs`;
export const ajaxBindedHubDetailedURL = `${api}/${AJAX}/hub-detail`;
export const ajaxBindedHubDetailedActualURL = `${api}/${AJAX}/hub-detail-actual`; */

// new SpAjaxUsersService();
export const spAjaxUsersURL = `${api}/${SP_AJAX_USERS}`;
export const spBindedGroupToCamURL = `${api}/sp-ajax-hubs/get-binded-groups`;

// new HubLocation();
export const getLocationDetailsURL = `${api}/${SP_AJAX_USERS}/id`;
export const getBindedHubIdInLocationURL = `${api}/sp-ajax-hubs/get-binded-id`;
export const getBindedHubInLocationURL = `${api}/sp-ajax-hubs/get-binded`;
export const verifyIfBindedAjaxUserURL = `${api}/sp-ajax-hubs/verify-bind`;
export const bindAjaxUserInLocationURL = `${api}/sp-ajax-hubs/bind`;
export const bindControllerInLocationURL = `${api}/sp-ajax-hubs/bind-controller`;

// ptz: {
//  enabled: true,
//  channel: {
//    idx: 0,
//    "name": ""
//   }
//  onArming: ['Preset', 0],
//  onDisarming: ['Scan', 1],
//  "Tour": true,
//  "TourMax": 8,
//  "TourMin": 1
//
//  "Preset": true,
//  "PresetMax": 300,
//  "PresetMin": 1,
//  "AutoScan": true,
//  "AutoScanMax": 5,
//  "AutoScanMin": 1,
//  "Pattern": true,
//  "PatternMax": 5,
//  "PatternMin": 1,
// }
