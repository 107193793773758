import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {
  DashboardOutlined,
  UserOutlined,
  DesktopOutlined,
} from "@ant-design/icons";
import { Button, ConfigProvider, Layout, Menu } from "antd";
import { Header } from "antd/es/layout/layout";

import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../src/services/config";
import useAuthenthication from "./hooks/useAuthentication";
import { useSelector } from "react-redux";
import { IUser } from "./models/user";
import { ItemType } from "antd/es/menu/hooks/useItems";

const { Sider, Content } = Layout;

const navigation = [
  { label: "Показатели", key: "/", icon: <DashboardOutlined size={24} /> },
  {
    label: "AJAX",
    icon: <DashboardOutlined />,
    children: [
      {
        label: "Пользователи",
        key: "/ajax-users",
        icon: <UserOutlined size={24} />,
      },
    ],
  },
  {
    label: "Клиенты",
    key: "/clients",
    icon: <UserOutlined size={24} />,
  },
  {
    label: "Управление",
    key: "/operators",
    icon: <DesktopOutlined size={24} />,
  },
] as ItemType[];

const AppPage: React.FC = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);

  const { logoutCall } = useAuthenthication();
  const user = useSelector(({ UserSlice }: any) => UserSlice.user as IUser);

  const handleMenuClick = ({ key }: any) => {
    if (key) {
      navigate(key);
    }
  };

  const signOutUser = async () => await logoutCall();

  useEffect(() => {
    document.title = "SP API";
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#27C77A",
        },
      }}
    >
      <Layout style={{ height: "100vh" }}>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          style={{ height: "100%" }}
        >
          <div
            style={{
              height: 32,
              margin: 16,
              background: "rgba(255, 255, 255, 0.2)",
            }}
            className="LOGOTYPE"
          />
          <Menu
            theme="dark"
            defaultSelectedKeys={["/"]}
            mode="inline"
            items={navigation}
            onClick={handleMenuClick}
          />
        </Sider>

        <Layout className="site-layout">
          <Header
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              background: "#2c2c2c",
            }}
          >
            <Button onClick={signOutUser}>Выйти - {user?.email}</Button>
          </Header>
          <Content style={{ margin: "16px" }}>
            <div
              style={{
                padding: 24,
                height: "100vh",
                background: "#fff",
              }}
            >
              <Outlet />
            </div>
          </Content>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default AppPage;
