import { Button, Form, Modal, Radio, Select } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { IUser } from "../../models/user";
import { useParams } from "react-router-dom";
import { AjaxService } from "../../ducks/ajax/ajax.service";
import { HubLocationService } from "../../ducks/hub-location/hub.location";
import { IClientType } from "../../components/clients/create.client";

interface MyObject {
  state: string;
  value: boolean;
  partition: string;
}

interface IBindUserSpace {
  ajaxService: AjaxService;
  hubLocationService: HubLocationService;
}

const deps: IBindUserSpace = {
  ajaxService: new AjaxService(),
  hubLocationService: new HubLocationService(),
};

function createObjects(n: number): MyObject[] {
  const objects: MyObject[] = [];

  for (let i = 1; i <= n; i++) {
    objects.push({
      state: "ARM",
      value: false,
      partition: i < 10 ? `0${i}` : `${i}`,
    });
  }

  return objects;
}

interface CollectionCreateFormProps {
  open: boolean;
  onCreate: (values: any) => void;
  onCancel: () => void;
}

const siaAccounts = [
  {
    label: "0000000001",
    value: "0000000001",
  },
  {
    label: "0000000002",
    value: "0000000002",
  },
];

const BindController = () => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);

  const user = useSelector(({ UserSlice }: any) => UserSlice.user as IUser);

  const BindHubInSpace: React.FC<CollectionCreateFormProps> = ({
    open,
    onCreate,
    onCancel,
  }) => {
    const [form] = Form.useForm();
    const [confirmLoading, setConfirmLoading] = useState(false);

    return (
      <Modal
        open={open}
        title="Подключение контроллера"
        okText="Добавить"
        cancelText="Отменить"
        onCancel={onCancel}
        confirmLoading={confirmLoading}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              setConfirmLoading(true);
              onCreate(values);

              setTimeout(() => {
                setConfirmLoading(false);
              }, 1000);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form form={form} layout="vertical" name="form_in_modal">
          <Form.Item
            name="account"
            label="SIA Account"
            rules={[
              {
                required: true,
                message: "SIA account объязателен!",
              },
            ]}
          >
            <Select options={siaAccounts} />
          </Form.Item>

          <Form.Item
            name="partitions"
            label="Кол-во зон"
            rules={[
              {
                required: true,
                message: "Кол-во зон объязателено!",
              },
            ]}
          >
            <Radio.Group>
              <Radio value={8}>8</Radio>
              <Radio value={12}>12</Radio>
              <Radio value={16}>16</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const handleBindController = async (value: any) => {
    const zones = createObjects(value.partitions);
    const payload = {
      zones,
      hubId: value.account,
      userId: user.email,
      spaceId: id,
      type: IClientType.Local,
    };

    const response = await deps.hubLocationService.bindControllerInLocation(
      payload
    );

    if (response.hubId) {
      window?.location?.reload();
    }
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => setOpen(true)}
        style={{ marginBottom: 16 }}
      >
        Привязать хаб
      </Button>

      <BindHubInSpace
        open={open}
        onCreate={handleBindController}
        onCancel={() => setOpen(false)}
      />
    </>
  );
};

export default BindController;
