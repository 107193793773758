import axios from "axios";
import store from "../redux/store";
import { setUser } from "../redux/UserSlice"; // Импорт экшена для обновления токена

// Функция для получения нового access токена с помощью refresh токена
const refreshAccessToken = async () => {
  try {
    const refreshToken = localStorage.getItem("refresh_token"); // Получаем refresh токен
    if (!refreshToken) throw new Error("No refresh token available");

    const response = await axios.post("/authentication/refresh-token", {
      refresh_token: refreshToken,
    });

    const newAccessToken = response.data.access_token;

    // Обновляем accessToken в Redux store
    store.dispatch(setUser({ accessToken: newAccessToken }));

    // Возвращаем новый accessToken для повторного использования
    return newAccessToken;
  } catch (error) {
    console.error("Ошибка обновления access токена:", error);
    throw error;
  }
};

// Интерцептор для запросов
axios.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state.UserSlice.accessToken;

    console.log("token store", token);

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Интерцептор для ответов
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Проверяем, если это ошибка авторизации
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Чтобы не зациклить запрос

      try {
        const newAccessToken = await refreshAccessToken();

        // Устанавливаем новый accessToken в заголовки и повторяем запрос
        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
        return axios(originalRequest);
      } catch (err) {
        // Если обновление токена не удалось, перенаправляем пользователя на страницу логина
        console.error("Redirecting to login due to token refresh failure");
        window.location.href = "/sign-in"; // Или другой механизм перенаправления
      }
    }

    return Promise.reject(error);
  }
);
