import { api } from "../../services/helpers";

import { HttpService } from "../http/HttpService";

const http = new HttpService(`${api}/`);

export class CamListService {
  async getCamList(hubId: any): Promise<any> {
    return await http.post<any>(`sp-ajax-cams/get-hub-cameras`, hubId);
  }

  async deleteCamera(payload: any) {
    return await http.post<any>(`sp-ajax-cams/delete-camera`, payload);
  }
}
