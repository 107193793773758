import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ISpAjaxUser } from "../../ducks/ajax/model";
import { SpAjaxUsersService } from "../../ducks/ajax-users/sp-ajax.users.service";
import { HubLocationService } from "../../ducks/hub-location/hub.location";

import { BindUserInSpace } from "./bind.user.space";
import { AjaxHubSpaceList } from "./ajax-hub.space.list";
import { LocationDetails } from "./space.details";
import { ISpaceDetail } from "../../models/space";
import { IClientType } from "../../components/clients/create.client";
import DahuaControllerSpace from "./dahua-controller.space";
import BindController from "./bind.controller";

interface ICreateUserProps {
  HubLocationService: HubLocationService;
  spAjaxUsersService: SpAjaxUsersService;
}

const deps: ICreateUserProps = {
  HubLocationService: new HubLocationService(),
  spAjaxUsersService: new SpAjaxUsersService(),
};

export const ClientHubPage = () => {
  const { id } = useParams();
  const [users, setUsers] = useState<ISpAjaxUser[]>([]);
  const [space, setSpaceDetail] = useState<ISpaceDetail>();

  const getLocationDetailedInfo = async () => {
    try {
      const details = await deps.HubLocationService.getLocationDetail(
        id as string
      );
      setSpaceDetail(details);

      if (details?.type === IClientType.Cloud) {
        getAvailableSpAjaxUsers();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAvailableSpAjaxUsers = async () => {
    const spUsers = await deps.spAjaxUsersService.getSPAjaxUsers();
    setUsers(spUsers as ISpAjaxUser[]);
  };

  useEffect(() => {
    getLocationDetailedInfo();
  }, [id]);

  const renderCloudUI = () => (
    <>
      <BindUserInSpace users={users} />
      <AjaxHubSpaceList />
    </>
  );

  const renderLocalUI = () => (
    <>
      <BindController />
      <DahuaControllerSpace />
    </>
  );

  return (
    <div>
      <LocationDetails space={space as ISpaceDetail} />

      {space?.type === IClientType.Cloud && renderCloudUI()}
      {space?.type === IClientType.Local && renderLocalUI()}
    </div>
  );
};
