import axios, { AxiosRequestConfig } from "axios";
import store from "../../redux/store";

export class HttpService {
  private baseURL: string;

  constructor(baseURL: string) {
    this.baseURL = baseURL;
  }

  // Метод для GET-запросов
  async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const token = store.getState().UserSlice.accessToken;
    const headers = {
      Authorization: `Bearer ${token}`,
      ...config?.headers,
    };

    const response = await axios.get<T>(`${this.baseURL}${url}`, {
      ...config,
      headers,
    });
    return response.data;
  }

  async post<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    const token = store.getState().UserSlice.accessToken;
    const headers = {
      Authorization: `Bearer ${token}`,
      ...config?.headers,
    };

    const response = await axios.post<T>(`${this.baseURL}${url}`, data, {
      ...config,
      headers,
    });
    return response.data;
  }

  async delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const token = store.getState().UserSlice.accessToken;
    const headers = {
      Authorization: `Bearer ${token}`,
      ...config?.headers,
    };

    const response = await axios.delete<T>(`${this.baseURL}${url}`, {
      ...config,
      headers,
    });
    return response.data;
  }
}
